export enum DATA_TYPE {
    COMPANY = 'company',
    BANK = 'banks',
    ADDRESS = 'address', 
    CONTACT = 'contact',
    SOCIAL_MEDIA = 'socialNetwork',
    PARTNERS = 'partners',
    REFERENCES = 'reference',
    DOCUMENTS = 'document',
    CONTRACT = 'terms'
}