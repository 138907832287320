import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class PostalCode {

    constructor(private http: HttpClient) { }

    get(cep: any): Observable<any> {
        return this.http.get(`https://viacep.com.br/ws/${cep}/json/`)
    }
}